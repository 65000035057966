import { createSlice } from "@reduxjs/toolkit";

interface BackupData {
  _id: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  start_date?: string; 
  end_date?: string;   
}

interface InitialBackupData {
  backupdata: BackupData[];
}

const initialBackupData: InitialBackupData = {
  backupdata: [], // Initialize with null
};

const BackupSlice = createSlice({
  name: "backupdata",
  initialState: initialBackupData,
  reducers: {
    fetchBackupdata:(state, action)=> {
      state.backupdata = action.payload; 
    },

  

  },
});

export const { fetchBackupdata } = BackupSlice.actions;
export default BackupSlice.reducer;

// Selector to access backup data in components
export const selectBackupData = (state: any) => state.backup?.backupdata

