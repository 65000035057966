// Created By Yash l.

import { createSlice ,PayloadAction} from "@reduxjs/toolkit";
import { AnyIfEmpty } from "react-redux";

interface InitialEarningsState {
  myEarnings: any;
  payHistory: any;
  myEarningsTotBal: any;
  rowCount: number;
  rowPayHisCount: number;
  MyData:any

}
const InitialEarningsState: InitialEarningsState = {
  myEarnings: [],
  myEarningsTotBal: [],
  payHistory: [],
  rowCount: 0,
  rowPayHisCount: 0,
  MyData:{}
};

const MyEarningsSlice = createSlice({
  name: "earnings",
  initialState: InitialEarningsState,
  reducers: {
    fetchEarnings(state: any, action: any) {
      state.myEarnings = action.payload;
    },
    fetchEarningSummary(state: any, action: any) {
      state.myEarningsTotBal = action.payload;
    },
    fetchPayHistory(state: any, action: any) {
      state.payHistory = action.payload;
    },
    rejectPayment(state: any, action: { payload: { _id: string } }) {
      const { _id } = action.payload;

      state.payHistory = state.payHistory.map((payment: any) =>
        payment._id === _id ? { ...payment, status: 3 } : payment
      );
    },
    approvalPayment(state: any, action: { payload: { _id: any } }) {
      const { _id } = action.payload;

      state.payHistory = state.payHistory.map((payment: any) =>
        payment._id === _id ? { ...payment, status: 2 } : payment
      );
    },
    setEarningsRowCount(state: any, action: any) {
      state.rowCount = action.payload;
    },
    setPayHistoryRowCount(state: any, action: any) {
      state.rowPayHisCount = action.payload;
    },
    // addWithdrawReq(state: any, action: any) {
    //   state?.myEarnings?.push(action.payload);
    // },
    userPoints(state: any, action: any) {
      state.MyData = action.payload;
     
    },
  },
});

export const { fetchEarnings, setEarningsRowCount, fetchPayHistory, rejectPayment, setPayHistoryRowCount, approvalPayment, fetchEarningSummary,userPoints } = MyEarningsSlice.actions;

export default MyEarningsSlice.reducer;

export const earningData = (state: any) => state.earnings.myEarnings;

export const earningTotBal = (state: any) => state.earnings.myEarningsTotBal;

export const paymentHistoryData = (state: any) => state.earnings.payHistory;

export const earningsRowCount = (state: any) => state.earnings.rowCount;

export const payHisRowCount = (state: any) => state.earnings.rowPayHisCount;

export const earingRemdeempoints = (state: any) => state.earnings?.MyData;
